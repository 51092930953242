<template>
    <template v-if="props.textLinkSpec.enableUrl">
        <NuxtLink 
            :id="props.textLinkSpec.buttonElName" 
            class="gibs-text-link flex flex-row" :class="props.textLinkSpec.class" 
            :to='props.textLinkSpec.sourceUrl' 
            :target="openNewTab"
        >
            <strong>{{ props.textLinkSpec.buttonText }}</strong>
        </NuxtLink>
    </template>
    <template v-else>
        <NuxtLink 
            :id="props.textLinkSpec.buttonElName" 
            class="gibs-text-link flex flex-row" :class="props.textLinkSpec.class" 
        >
            <strong>{{ props.textLinkSpec.buttonText }}</strong>
        </NuxtLink>
    </template>
</template>

<script setup lang="ts">

    const props = defineProps({
        textLinkSpec: {
            type: Object,
            default: buttonObject
        }
    });

    const openNewTab = props.textLinkSpec.openNewTab? "_blank" : "";

</script>

<script lang="ts">
    var buttonObject:IControlButton = {
        buttonElName: "text-link",
        buttonText: "Find out more",
        class: "",
        enableUrl: true,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/"
    }
</script>